import React from "react";
import { graphql } from "gatsby";
import { Contact, PageTemplateWrapper } from "@resourcehub/resourcehub-components";
import configGenerator from "@resourcehub/resourcehub-configuration";
import "../../styles/main.less";

const Page = ({ data }) => {
    const { copy, site } = data;
    const config = configGenerator({
        market: site.siteMetadata.market,
        site: "education-primary",
        locale: site.siteMetadata.lang,
        page: "cn-education-primary-contact"
    });
    return (
        <PageTemplateWrapper config={config}>
            <Contact
                copyData={copy.data}
                domain={site.siteMetadata.legacy.domain}
                heroImgAlt="Business language speakers"
                heroImgSrc="https://resources.rosettastone.com/assets/lp/9999999998/resources/imgs/bus-hero-1.png"
                vertical="hed"
            />
        </PageTemplateWrapper>
    );
};

export default Page;

export const pageQuery = graphql`
    query {
        copy: airtable(data: { title: { eq: "cn-copy" } }) {
            ...PagesCopyFragment
        }
        site {
            ...SiteFragment
        }
    }
`;
